body {
  margin: 0;
  font-family: Inter, apple color emoji, Helvetica, Verdana, sans-serif;
  background-color: white;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

button {
  font-family: inherit;
  font-size: 1em;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  ::-webkit-scrollbar {
    /* Safari and Chrome */
    display: none;
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }
}


input:focus,
textarea:focus,
select:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

.ProseMirror {
  width: 100%;
}

.ProseMirror p {
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
}

.ProseMirror h1 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.ProseMirror h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 6px;
}

.ProseMirror h3 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 4px;
}

.ProseMirror:focus {
  outline: none;
}

/* RichTextEditor Placeholder */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--gray-7);
  pointer-events: none;
  height: 0;
}

.ProseMirror p.center-align-placeholder:first-child::before {
  content: attr(data-placeholder);
  text-align: center;
  float: none;
  color: #828585;
  pointer-events: none;
  height: 0;
}

.ProseMirror p.response-option-match-item-label:first-child::before {
  content: attr(data-placeholder);
  text-align: center;
  float: none;
  color: var(--gray-6);
  pointer-events: none;
  height: 0;
}

.ProseMirror p.response-option-match-item-text:first-child::before {
  content: attr(data-placeholder);
  text-align: center;
  float: none;
  opacity: 0.2;
  color: inherit;
  pointer-events: none;
  height: 0;
}

div.sidebar-prompt>* {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 36px;
}

div.sidebar-prompt>*:not(:first-child) {
  display: none;
}

div.sidebar-prompt>ol,
div.sidebar-prompt>ul,
div.ProseMirror>ol,
div.ProseMirror>ul {
  margin: 0;
  padding-inline-start: 20px;
}

.mention {
  color: #2e72ef;
  background-color: #f0f5fe;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 15px;
}

.rich-text-link {
  color: #733BFF;
  word-break: break-all;
}

.PhoneInputCountry {
  --PhoneInputCountrySelect-marginRight: 12px;
}

@font-face {
  font-family: "PolySans";
  src:
    url("../../public/fonts/PolySans/PolySans-Median.woff2") format("woff2"),
    url("../../public/fonts/PolySans/PolySans-Median.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

[data-highlighted] {
  background-color: #f5f6f6;
}

.list-indentation-8 {
  margin-left: 8px;
}

.list-indentation-16 {
  margin-left: 16px;
}

.list-indentation-24 {
  margin-left: 24px;
}

.ag-theme-quartz.ag-custom-class {
  --ag-row-border-color: #EFF0F1;
  --ag-grid-size: 1px;
  --ag-cell-horizontal-border: var(--ag-row-border-width) var(--ag-row-border-style) var(--ag-row-border-color);
  --ag-header-column-separator-display: block;
  --ag-header-foreground-color: #828585;
  --ag-header-background-color: white;
  --ag-row-hover-color: #f5f6f6 !important;
  --ag-column-hover-color: #f5f6f6 !important;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --white: white;
    --black: black;
    --brand-green: #054540;
    --brand-yellow: #feda65;
    --brand-sky: #aebefd;
    --brand-purple: #51184d;
    --brand-lilac: #775172;
    --gray-1: #f7f7f8;
    --gray-2: #e9eaec;
    --gray-3: #dee0e3;
    --gray-4: #c7c9d0;
    --gray-5: #babdc5;
    --gray-6: #9ea2ad;
    --gray-7: #7e869a;
    --gray-8: #717684;
    --gray-9: #5e636e;
    --gray-10: #464a53;
    --gray-11: #333333;
    --gray-12: #1f2228;
    --gray-13: #14151a;
    --gray-1-alpha: #e9eaec0a;
    --gray-2-alpha: #e9eaec14;
    --gray-3-alpha: #dee0e323;
    --gray-4-alpha: #c7c9d033;
    --gray-5-alpha: #babdc53f;
    --gray-6-alpha: #9ea2ad4c;
    --gray-7-alpha: #7e869a66;
    --gray-8-alpha: #7176847f;
    --gray-9-alpha: #5e636e99;
    --gray-10-alpha: #464a53b2;
    --gray-11-alpha: #333333cc;
    --gray-12-alpha: #1f2228e5;
    --gray-13-alpha: #14151af2;
    --blue-1: #f0f4fe;
    --blue-2: #e3eafd;
    --blue-3: #ccd9fa;
    --blue-4: #b4c7f8;
    --blue-5: #93aff6;
    --blue-6: #7196f4;
    --blue-7: #5984f2;
    --blue-8: #4677f5;
    --blue-9: #1d54e2;
    --blue-10: #1a4ac7;
    --blue-11: #13399a;
    --blue-12: #07296a;
    --blue-13: #03153a;
    --blue-1-alpha: #3368f019;
    --blue-2-alpha: #3368f026;
    --blue-3-alpha: #3368f033;
    --blue-4-alpha: #3368f03f;
    --blue-5-alpha: #3368f04c;
    --blue-6-alpha: #3368f066;
    --blue-7-alpha: #3368f07f;
    --blue-8-alpha: #3368f099;
    --blue-9-alpha: #3368f0b2;
    --blue-10-alpha: #3368f0cc;
    --blue-11-alpha: #3368f0e5;
    --green-1: #edfdf4;
    --green-2: #d1fae4;
    --green-3: #c3f8dc;
    --green-4: #9af4c3;
    --green-5: #8ce8b6;
    --green-6: #6ae1a1;
    --green-7: #40d986;
    --green-8: #26bd6c;
    --green-9: #21a65e;
    --green-10: #1d9052;
    --green-11: #166e3f;
    --green-1-alpha: #26bd6c19;
    --green-2-alpha: #26bd6c26;
    --green-3-alpha: #26bd6c33;
    --green-4-alpha: #26bd6c3f;
    --green-5-alpha: #26bd6c4c;
    --green-6-alpha: #26bd6c66;
    --green-7-alpha: #26bd6c7f;
    --green-8-alpha: #26bd6c99;
    --green-9-alpha: #26bd6cb2;
    --green-10-alpha: #26bd6ccc;
    --green-11-alpha: #26bd6ce5;
    --green-12: #0f4c2c;
    --green-13: #072213;
    --orange-1: #fef4ec;
    --orange-2: #fdead8;
    --orange-3: #fcddc0;
    --orange-4: #fad0a9;
    --orange-5: #f9c594;
    --orange-6: #f8b577;
    --orange-7: #f6a355;
    --orange-8: #f48e2f;
    --orange-9: #e9760c;
    --orange-10: #d56c0b;
    --orange-11: #ae590a;
    --orange-12: #613105;
    --orange-13: #301903;
    --orange-1-alpha: #f48e2f19;
    --orange-2-alpha: #f48e2f26;
    --orange-3-alpha: #f48e2f33;
    --orange-4-alpha: #f48e2f3f;
    --orange-5-alpha: #f48e2f4c;
    --orange-6-alpha: #f48e2f66;
    --orange-7-alpha: #f48e2f7f;
    --orange-8-alpha: #f48e2f99;
    --orange-9-alpha: #f48e2fb2;
    --orange-10-alpha: #f48e2fcc;
    --orange-11-alpha: #f48e2fe5;
    --red-1: #fdf2f1;
    --red-2: #fce5e4;
    --red-3: #f9d4d2;
    --red-4: #f7c3c0;
    --red-5: #f5b2ad;
    --red-6: #f08b85;
    --red-7: #eb6960;
    --red-8: #e6483d;
    --red-9: #d9281c;
    --red-10: #be2318;
    --red-11: #9a1c13;
    --red-12: #64120d;
    --red-13: #360a07;
    --red-1-alpha: #e6483d19;
    --red-2-alpha: #e6483d26;
    --red-3-alpha: #e6483d33;
    --red-4-alpha: #e6483d3f;
    --red-5-alpha: #e6483d4c;
    --red-6-alpha: #e6483d66;
    --red-7-alpha: #e6483d7f;
    --red-8-alpha: #e6483d99;
    --red-9-alpha: #e6483db2;
    --red-10-alpha: #e6483dcc;
    --red-11-alpha: #e6483de5;
    --purple-1: #f7f1fd;
    --purple-2: #ecdffb;
    --purple-3: #e1cdf9;
    --purple-5: #cdaef4;
    --purple-4: #d8c0f7;
    --purple-6: #b78af0;
    --purple-7: #a165eb;
    --purple-8: #924ee8;
    --purple-9: #731de1;
    --purple-10: #6619c7;
    --purple-11: #5314a3;
    --purple-12: #390e70;
    --purple-13: #1c0736;
    --purple-1-alpha: #873de619;
    --purple-2-alpha: #873de626;
    --purple-3-alpha: #873de633;
    --purple-10-alpha: #873de6cc;
    --purple-9-alpha: #873de6b2;
    --purple-8-alpha: #873de699;
    --purple-7-alpha: #873de67f;
    --purple-6-alpha: #873de666;
    --purple-5-alpha: #873de64c;
    --purple-4-alpha: #873de63f;
    --purple-11-alpha: #873de6e5;
    --yellow-1: #fdf9ec;
    --yellow-2: #fdf7df;
    --yellow-3: #fff2c2;
    --yellow-4: #faeaad;
    --yellow-5: #f9e597;
    --yellow-6: #fade72;
    --yellow-7: #f7d651;
    --yellow-8: #f4ca25;
    --yellow-9: #f1c105;
    --yellow-10: #b48f00;
    --yellow-11: #8b7313;
    --yellow-12: #3f3200;
    --yellow-13: #201901;
    --yellow-1-alpha: #f1c10519;
    --yellow-2-alpha: #f1c10526;
    --yellow-3-alpha: #f1c10533;
    --yellow-4-alpha: #f1c1053f;
    --yellow-5-alpha: #f1c1054c;
    --yellow-6-alpha: #f1c10566;
    --yellow-7-alpha: #f1c1057f;
    --yellow-8-alpha: #f1c10599;
    --yellow-9-alpha: #f1c105b2;
    --yellow-10-alpha: #f1c105cc;
    --yellow-11-alpha: #f1c104e5;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@media print {

  body,
  html {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    /* Chrome on PC */
    scrollbar-width: none;
  }
}

@layer utilities {
  @keyframes ai-border {
    0% {
      border-color: #ff69b4;
    }

    /* Pink */
    33% {
      border-color: #8a2be2;
    }

    /* Purple */
    66% {
      border-color: #1e90ff;
    }

    /* Blue */
    100% {
      border-color: #ff69b4;
    }

    /* Back to Pink */
  }

  .animate-ai-border {
    animation: ai-border 3s linear infinite;
  }
}

.ProseMirror-minimal-headings {

  h1,
  h2,
  h3 {
    width: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
    color: "pink";
  }
}


.rich-text-opus-doc-editor {

  /* Block element resets */
  p,
  blockquote,
  figure {
    margin: revert;
  }

  /* Base styles */
  font-family: Arial,
  sans-serif;
  width: 100%;
  height: 100%;
  line-height: 1.6;
  flex: 1;
  border: none;
  padding: 0;

  figure {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-3);
    margin: 16px 0;
  }

  pre {
    background-color: #f5f5f5;
    padding: 16px;
    border-radius: 4px;
    overflow-x: auto;
    margin: 16px 0;
  }

  code {
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 3px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 16px 0;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  ul,
  ol {
    padding-left: 2em;
    margin: 16px 0;
  }

  li {
    margin: 4px 0;
  }

  strong {
    font-weight: 600;
  }

  p {
    margin: 16px 0;
  }

}